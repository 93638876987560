import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BetLimits } from 'pages/MatchDetail';
import snackbarUtil from 'Utils/snackBarUtil';

interface MatchSettingProps {
    limits?: BetLimits | undefined;
    id?: string | undefined;
    getLimit?: any;
    fancyShow?: boolean;
    shoeOdss?: boolean;
    marketId?: string;
    sportName: string;
    combineData: any;
    setFormData: any;
    formData: any;
    setErrors: Dispatch<any>;
    errors: any;
    combineDataParent: any;
    validationError: any;
    setTooltipMsg: Dispatch<SetStateAction<{
        [key: string]: string;
    }>>
    tooltipMsg: {
        [key: string]: string;
    }
    setShowTooltip: Dispatch<SetStateAction<{
        [key: string]: boolean;
    }>>
    showTooltip: {
        [key: string]: boolean;
    }
}



const MarketSettingForm = ({ setShowTooltip, showTooltip, sportName, combineData, setFormData, formData, errors, setErrors, combineDataParent, tooltipMsg, setTooltipMsg }: MatchSettingProps) => {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numericValue = value === "" ? "" : Number(value);

        const updatedFormData = { ...formData, [name]: numericValue };
        const updatedErrors = { ...errors };
        const newTooltipMsg = { ...tooltipMsg };
        const newShowTooltip = { ...showTooltip };


        const addError = (message: string) => updatedErrors[name] = message;
        const addTooltip = (message: string) => {
            newTooltipMsg[name] = message;
            newShowTooltip[name] = true;
        };

        if (value === "") {
            addError("Value must be greater than 0.");
        } else {
            updatedErrors[name] = "";
        }


        const checks = [
            {
                condition: name === "market_min_stack" && updatedFormData?.market_max_stack && numericValue > updatedFormData?.market_max_stack,
                errorMsg: "Min. Stake cannot be greater than Max. Stake."
            },
            {
                condition: name === "market_max_stack" && updatedFormData?.market_min_stack && numericValue < updatedFormData?.market_min_stack,
                errorMsg: "Max. Stake cannot be less than Min. Stake."
            },
            {
                condition: name === "market_min_odds_rate" && updatedFormData?.market_max_odds_rate && numericValue > updatedFormData?.market_max_odds_rate,
                errorMsg: "Min. Odds Rate cannot be greater than Max. Odds Rate. Min. Odds Rate cannot be greater than Max. Odds Rate"
            },
            {
                condition: name === "market_max_odds_rate" && updatedFormData?.market_min_odds_rate && numericValue < updatedFormData?.market_min_odds_rate,
                errorMsg: "Max. Odds Rate cannot be less than Min. Odds Rate."
            },
            {
                condition: name === "market_bookmaker_min_odds_rate" && updatedFormData?.market_bookmaker_max_odds_rate && numericValue > updatedFormData?.market_bookmaker_max_odds_rate,
                errorMsg: "Bookmaker Min. Odds Rate cannot be greater than Bookmaker Max. Odds Rate."
            },
            {
                condition: name === "session_min_stack" && updatedFormData?.session_max_stack && numericValue > updatedFormData?.session_max_stack,
                errorMsg: "Session Min. Stack  cannot be greater than Session Max. Stack."
            },
            {
                condition: name === "session_max_stack" && updatedFormData?.session_min_stack && numericValue < updatedFormData?.session_min_stack,
                errorMsg: "Session Min. Stack  cannot be greater than Session Max. Stack."
            }
        ];

        const parentChecks = [
            {
                condition: name === "market_min_stack" && numericValue < updatedFormData?.market_min_stack,
                tooltipMsg: `Market Min Stack value should not be less than parent value (${updatedFormData?.market_min_stack})`
            },
            {
                condition: name === "market_max_stack" && numericValue > updatedFormData?.market_max_stack,
                tooltipMsg: `Market Max Stack value should not be greater than parent value (${updatedFormData?.market_max_stack})`
            },
            {
                condition: name === "market_max_odds_rate" && numericValue > updatedFormData?.market_max_odds_rate,
                tooltipMsg: `Market Max Odds Rate value should not be greater than parent value (${updatedFormData?.market_max_odds_rate})`
            },
            {
                condition: name === "market_bookmaker_min_odds_rate" && Number(numericValue) < 2,
                tooltipMsg: `Market Bookmaker Min Odds Rate should between (1-100)`
            },
            {
                condition: name === "market_bookmaker_max_odds_rate" && Number(numericValue) > 100,
                tooltipMsg: "Market Bookmaker Max Odds Rate should not exceed 100"
            },
            {
                condition: name === "market_max_profit" && numericValue > updatedFormData?.market_max_profit,
                tooltipMsg: `Market Max Profit value should not be greater than parent value (${updatedFormData?.market_max_profit})`
            },
            {
                condition: name === "session_max_stack" && numericValue > updatedFormData?.session_max_stack,
                tooltipMsg: `Session Max Stack value should not exceed parent value (${updatedFormData?.session_max_stack})`
            },
            {
                condition: name === "session_max_profit" && numericValue > updatedFormData?.session_max_profit,
                tooltipMsg: `Session Max Profit value should not exceed parent value (${updatedFormData?.session_max_profit})`
            },
            {
                condition: name === "market_bet_delay" && Number(numericValue) > 10,
                tooltipMsg: "Match Bets Delay should not exceed 10"
            },
            {
                condition: name === "session_bet_delay" && Number(numericValue) > 10,
                tooltipMsg: "Session Bets Delay should not exceed 10"
            }
        ];

        checks.forEach(({ condition, errorMsg }) => {
            if (condition) addError(errorMsg);
        });

        parentChecks.forEach(({ condition, tooltipMsg }) => {
            if (condition) addTooltip(tooltipMsg);
        });

        if (!(checks.some(({ condition }) => condition) || parentChecks.some(({ condition }) => condition))) {
            newTooltipMsg[name] = "";
            newShowTooltip[name] = false;
        }

        setFormData(updatedFormData);
        setErrors(updatedErrors);
        setTooltipMsg(newTooltipMsg);
        setShowTooltip(newShowTooltip);
    };

    const handleBlur = (name: string) => {
        const hasTooltip = Object.keys(tooltipMsg).length > 0;
        setShowTooltip((prev) => ({
            ...prev,
            [name]: hasTooltip && !!tooltipMsg[name],
        }));
    };

    useEffect(() => {
        setFormData({
            market_advance_bet_stake: combineData?.market_advance_bet_stake,
            market_bet_delay: combineData?.market_bet_delay,
            market_bookmaker_max_odds_rate: combineData?.market_bookmaker_max_odds_rate,
            market_bookmaker_min_odds_rate: combineData?.market_bookmaker_min_odds_rate,
            market_max_profit: combineData?.market_max_profit,
            market_max_stack: combineData?.market_max_stack,
            market_min_odds_rate: combineData?.market_min_odds_rate,
            market_min_stack: combineData?.market_min_stack,
            session_bet_delay: combineData?.session_bet_delay,
            session_max_profit: combineData?.session_max_profit,
            session_max_stack: combineData?.session_max_stack,
            session_min_stack: combineData?.session_min_stack,
            market_max_odds_rate: combineData?.market_max_odds_rate,
        });
    }, [combineData]);




    return (
        <Form>
            <Row className="g-3">
                <h5 className='text-primary mb-0'>Market Setting</h5>

                <Col lg={4}>
                    <OverlayTrigger
                        show={!!showTooltip?.market_min_stack}
                        placement="top"
                        overlay={<Tooltip>{tooltipMsg.market_min_stack}</Tooltip>}
                    >
                        <div className="form-floating">
                            <Form.Control
                                name="market_min_stack"
                                type="number"
                                id="MinStakeAmount"
                                placeholder="Min. Stake Amount"
                                value={formData.market_min_stack}
                                onChange={handleInputChange}
                                onBlur={() => handleBlur("market_min_stack")}
                                isInvalid={!!errors.market_min_stack}
                                onKeyDown={(e) => {
                                    if (['-', 'e', '+', 'E', '.'].includes(e.key)) e.preventDefault();
                                }}
                            />
                            <Form.Label htmlFor="MinStakeAmount">Min. Stake Amount</Form.Label>
                            {errors.market_min_stack && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.market_min_stack}
                                </Form.Control.Feedback>
                            )}
                        </div>
                    </OverlayTrigger>
                </Col>

                <Col lg={4}>
                    <OverlayTrigger
                        show={!!showTooltip?.market_max_stack}
                        placement="top"
                        overlay={<Tooltip>{tooltipMsg.market_max_stack}</Tooltip>}
                    >
                        <div className="form-floating">
                            <Form.Control
                                name="market_max_stack"
                                type="number"
                                id="MaxStakeAmount"
                                placeholder="Max. Stake Amount"
                                value={formData.market_max_stack}
                                onChange={handleInputChange}
                                isInvalid={!!errors.market_max_stack}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') { e.preventDefault(); }
                                }}
                            />
                            <Form.Label htmlFor="MaxStakeAmount">Max. Stake Amount</Form.Label>
                            {errors.market_max_stack && <Form.Control.Feedback type="invalid">{errors.market_max_stack}</Form.Control.Feedback>}
                        </div>
                    </OverlayTrigger>
                </Col>

                <Col lg={4}>

                    <div className="form-floating">
                        <Form.Control
                            name="market_min_odds_rate"
                            type="number"
                            id="MinOddsRate"
                            placeholder="Min. Odds Rate"
                            value={formData.market_min_odds_rate}
                            onChange={handleInputChange}
                            isInvalid={!!errors.market_min_odds_rate}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') { e.preventDefault(); }
                            }}
                        />
                        <Form.Label htmlFor="MinOddsRate">Min. Odds Rate</Form.Label>
                        {errors.market_min_odds_rate && <Form.Control.Feedback type="invalid">{errors.market_min_odds_rate}</Form.Control.Feedback>}
                    </div>
                </Col>

                <Col lg={4}>
                    <OverlayTrigger
                        show={!!showTooltip?.market_max_odds_rate}
                        placement="top"
                        overlay={<Tooltip>{tooltipMsg.market_max_odds_rate}</Tooltip>}
                    >
                        <div className="form-floating">
                            <Form.Control
                                name="market_max_odds_rate"
                                type="number"
                                id="MaxOddsRate"

                                placeholder="Max. Odds Rate"
                                value={formData?.market_max_odds_rate}
                                onChange={handleInputChange}
                                isInvalid={!!errors.market_max_odds_rate}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') { e.preventDefault(); }
                                }}
                            />
                            <Form.Label htmlFor="MaxOddsRate">Max. Odds Rate</Form.Label>
                            {errors.market_max_odds_rate && <Form.Control.Feedback type="invalid">{errors.market_max_odds_rate}</Form.Control.Feedback>}
                        </div>
                    </OverlayTrigger>

                </Col>

                {sportName === "Cricket" && (
                    <>
                        <Col lg={4}>
                            <OverlayTrigger
                                show={!!showTooltip?.market_bookmaker_min_odds_rate}
                                placement="top"
                                overlay={<Tooltip>{tooltipMsg.market_bookmaker_min_odds_rate}</Tooltip>}
                            >
                                <div className="form-floating">
                                    <Form.Control
                                        name="market_bookmaker_min_odds_rate"
                                        type="number"
                                        id="BookmakerMinOddsRate"
                                        placeholder="Bookmaker Min. Odds Rate"
                                        value={formData?.market_bookmaker_min_odds_rate}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors?.market_bookmaker_min_odds_rate}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') { e.preventDefault(); }
                                        }}
                                    />
                                    <Form.Label htmlFor="BookmakerMinOddsRate">Bookmaker Min. Odds Rate</Form.Label>
                                    {errors.market_bookmaker_min_odds_rate && <Form.Control.Feedback type="invalid">{errors.market_bookmaker_min_odds_rate}</Form.Control.Feedback>}
                                </div>
                            </OverlayTrigger>
                        </Col>

                        <Col lg={4}>
                            <OverlayTrigger
                                show={!!showTooltip?.market_bookmaker_max_odds_rate}
                                placement="top"
                                overlay={<Tooltip>{tooltipMsg.market_bookmaker_max_odds_rate}</Tooltip>}
                            >
                                <div className="form-floating">
                                    <Form.Control
                                        name="market_bookmaker_max_odds_rate"
                                        type="number"
                                        id="BookmakerMaxOddsRate"
                                        placeholder="Bookmaker Max. Odds Rate"
                                        value={formData?.market_bookmaker_max_odds_rate}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.market_bookmaker_max_odds_rate}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') { e.preventDefault(); }
                                        }}
                                    />
                                    <Form.Label htmlFor="BookmakerMaxOddsRate">Bookmaker Max. Odds Rate</Form.Label>
                                    {errors.market_bookmaker_max_odds_rate && <Form.Control.Feedback type="invalid">{errors.market_bookmaker_max_odds_rate}</Form.Control.Feedback>}
                                </div>
                            </OverlayTrigger>
                        </Col>
                    </>
                )}

                <Col lg={4}>
                    <OverlayTrigger
                        show={!!showTooltip?.market_bet_delay}
                        placement="top"
                        overlay={<Tooltip>{tooltipMsg.market_bet_delay}</Tooltip>}
                    >
                        <div className="form-floating">
                            <Form.Control
                                name="market_bet_delay"
                                type="number"
                                id="BetDelay"
                                placeholder="Match Bets Delay"
                                value={formData?.market_bet_delay}
                                onChange={handleInputChange}
                                isInvalid={!!errors.market_bet_delay}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') { e.preventDefault(); }
                                }}
                            />
                            <Form.Label htmlFor="BetDelay">Match Bets Delay</Form.Label>
                            {errors.market_bet_delay && <Form.Control.Feedback type="invalid">{errors.market_bet_delay}</Form.Control.Feedback>}
                        </div>
                    </OverlayTrigger>
                </Col>

                <Col lg={4}>
                    <OverlayTrigger
                        show={!!showTooltip?.market_max_profit}
                        placement="top"
                        overlay={<Tooltip>{tooltipMsg.market_max_profit}</Tooltip>}
                    >
                        <div className="form-floating">
                            <Form.Control
                                name="market_max_profit"
                                type="number"
                                id="MarketMaxProfit"
                                placeholder="Market Max Profit"
                                value={formData.market_max_profit}
                                onChange={handleInputChange}
                                isInvalid={!!errors.market_max_profit}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') { e.preventDefault(); }
                                }}
                            />
                            <Form.Label htmlFor="MarketMaxProfit">Market Max Profit</Form.Label>
                            {errors.market_max_profit && <Form.Control.Feedback type="invalid">{errors.market_max_profit}</Form.Control.Feedback>}
                        </div>
                    </OverlayTrigger>
                </Col>

                {sportName === "Cricket" && (
                    <>
                        <h5 className='text-primary mb-0'>Session Setting</h5>
                        <Col lg={4}>

                            <div className="form-floating">
                                <Form.Control
                                    name="session_min_stack"
                                    type="number"
                                    placeholder="Session Min Stake"
                                    value={formData.session_min_stack}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.session_min_stack}
                                    onKeyDown={(e) => {
                                        if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <Form.Label>Session Min Stake</Form.Label>
                                {errors.session_min_stack && <Form.Control.Feedback type="invalid">{errors.session_min_stack}</Form.Control.Feedback>}
                            </div>
                        </Col>

                        <Col lg={4}>
                            <OverlayTrigger
                                show={!!showTooltip?.session_max_stack}
                                placement="top"
                                overlay={<Tooltip>{tooltipMsg.session_max_stack}</Tooltip>}
                            >
                                <div className="form-floating">
                                    <Form.Control
                                        name="session_max_stack"
                                        type="number"
                                        placeholder="Session Max Stake"
                                        value={formData.session_max_stack}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.session_max_stack}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <Form.Label>Session Max Stake</Form.Label>
                                    {errors.session_max_stack && <Form.Control.Feedback type="invalid">{errors?.session_max_stack}</Form.Control.Feedback>}
                                </div>
                            </OverlayTrigger>
                        </Col>

                        <Col lg={4}>
                            <OverlayTrigger
                                show={!!showTooltip?.session_max_profit}
                                placement="top"
                                overlay={<Tooltip>{tooltipMsg.session_max_profit}</Tooltip>}
                            >
                                <div className="form-floating">
                                    <Form.Control
                                        name="session_max_profit"
                                        type="number"
                                        placeholder="Session Max Profit"
                                        value={formData.session_max_profit}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.session_max_profit}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <Form.Label>Session Max Profit</Form.Label>
                                    {errors.session_max_profit && <Form.Control.Feedback type="invalid">{errors?.session_max_profit}</Form.Control.Feedback>}
                                </div>
                            </OverlayTrigger>
                        </Col>

                        <Col lg={4}>
                            <OverlayTrigger
                                show={!!showTooltip?.session_bet_delay}
                                placement="top"
                                overlay={<Tooltip>{tooltipMsg.session_bet_delay}</Tooltip>}
                            >
                                <div className="form-floating">
                                    <Form.Control
                                        name="session_bet_delay"
                                        type="number"
                                        placeholder="Session Bet Delay"
                                        value={formData.session_bet_delay}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.session_bet_delay}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E' || e.key === '.') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <Form.Label>Session Bet Delay</Form.Label>
                                    {errors.session_bet_delay && <Form.Control.Feedback type="invalid">{errors?.session_bet_delay}</Form.Control.Feedback>}
                                </div>
                            </OverlayTrigger>

                        </Col>
                    </>
                )}

            </Row>
        </Form>
    );
};

export default MarketSettingForm;
