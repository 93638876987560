import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Table, Button, OverlayTrigger, Popover, Form, ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

import {
  Column,
  Table as ReactTable,
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';
import { levelItem } from "./constant";

// Column Filter
const Filter = ({
  column
}: {
  column: Column<any, unknown>;
  table: ReactTable<any>;
}) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  );
};

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  );
};
interface TableContainerProps {
  columns?: any;
  data?: any;
  divClassName?: any;
  tableClass?: any;
  tdClass?: any;
  theadClass?: any;
  isBordered?: boolean;
  customPageSize?: number;
  isGlobalFilter?: boolean;
  isPagination: boolean;
  PaginationClassName?: string;
  SearchPlaceholder?: string;
  sorting?: boolean;
  rowClass?: any;
  isFilter?: boolean;
  setLimit?: any;
  setPageNo?: any;
  setFormValuesFilter?: any;
  formValuesFilter?: any;
  totalPages?: any;
  limit?: number;
  setCurrentPage?: any;
  currentPage?: any;
  totalData?: any;
  domainList?: DomainListPayload[];
  domainId?: string;
  showBck?: boolean;
  handleDomain?: (selectedOption: any) => void
  tableHeight?: string;
  handleLavelSelect?: (e: any) => void
  handleLevel?: (e: any) => void
  levelId?: string | number
}
const TableContainer = ({
  domainId,
  handleDomain,
  columns,
  data,
  tableClass,
  tdClass,
  theadClass,
  divClassName,
  isBordered,
  isPagination,
  customPageSize,
  isGlobalFilter,
  PaginationClassName,
  SearchPlaceholder,
  sorting,
  rowClass,
  isFilter,
  setLimit,
  setFormValuesFilter,
  formValuesFilter,
  totalPages,
  setCurrentPage,
  currentPage,
  totalData,
  domainList,
  showBck,
  tableHeight,
  handleLevel,
  levelId
}: TableContainerProps) => {

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [isStickyActive, setStickyActive] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [activeFilterColumn, setActiveFilterColumn] = useState<string | null>(null);
  const [showFilter, setShowFilter] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);

  const toggleFilter = (headerId: string) => {
    if (activeFilterColumn === headerId) {
      setActiveFilterColumn(null);
      setShowFilter(false);
    } else {
      setActiveFilterColumn(headerId);
      setShowFilter(true);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
        setShowFilter(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState
  } = table;

  useEffect(() => {
    Number(customPageSize) && setPageSize(Number(customPageSize));
  }, [customPageSize, setPageSize]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (scrollContainer) {
        const scrollLeft = scrollContainer.scrollLeft;
        setStickyActive(scrollLeft > 35);
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);



  const handleInputChange = (e: any, columnId: string) => {
    const { value } = e.target;
    setFormValuesFilter((prevValues: any) => ({
      ...prevValues,
      [columnId]: value
    }));
    // table.getColumn(columnId)?.setFilterValue(value);
  };

  useEffect(() => {
    setPageSize(customPageSize || 50);
  }, [customPageSize]);

  const handlePageClick = (item: number) => {
    setCurrentPage(item + 1);
    setPageIndex(item);

  };

  const handleNextPage = () => {
    nextPage();
    setCurrentPage(currentPage + 1);
  }
  const handlePreviousPage = () => {
    previousPage();
    setCurrentPage(currentPage - 1);
  };

  return (
    <Fragment>

      {isGlobalFilter && <DebouncedInput
        value={globalFilter ?? ''}
        onChange={value => setGlobalFilter(String(value))}
        className="p-2 font-lg shadow border border-block"
        placeholder={SearchPlaceholder}
      />}

      <div className={divClassName ? divClassName : "table-responsive"} ref={scrollContainerRef} style={{ minHeight: tableHeight }}>
        <Table hover className={tableClass} bordered={isBordered}>
          <thead className={theadClass}>
            {getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const isIdColumn = header.column.id === 'sNo';
                  const isTypeColumn = header.column.id === 'is_back';
                  const isIpColumn = header.column.id === 'ip';
                  const isExposure = header.column.id === 'exposure';
                  const isProfitLoss = header.column.id === 'profitLoss';
                  const isProfitLoss2 = header.column.id === 'profitLoss2';
                  const isBalance = header.column.id === 'balance';
                  const isCredit = header.column.id === 'credit';
                  const isUserStatus = header.column.id === 'userStatus';
                  const isBetStatus = header.column.id === 'betStatus';
                  const isAction = header.column.id === 'action';
                  const isDomian = header.column.id === 'DomainName';
                  const isCr = header.column.id === 'creditReference';
                  const isacType = header.column.id === 'acType';
                  const isMobile = header.column.id === 'mobile';
                  const isPts = header.column.id === 'Pts';
                  const islevelId = header.column.id === 'levelId';
                  return (
                    <th key={header.id} colSpan={header.colSpan}
                     
                    >
                      <div className="d-flex">
                        {header.isPlaceholder ? null : (
                          <React.Fragment>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' ',
                              desc: ' ',
                            }
                            [header.column.getIsSorted() as string] ?? null}
                            {/* {header.column.getCanFilter() ? (
                              <div>
                                <Filter column={header.column} table={table} />
                              </div>
                            ) : null} */}
                          </React.Fragment>
                        )}

                        {!isPts && !isMobile && !isacType && !isProfitLoss2 && !isCr && !isAction && !isBetStatus && !isUserStatus && !isBalance && !isCredit && !isIdColumn && !isIpColumn && !isExposure && !isProfitLoss && 
                          <i  {...(sorting && header.column.getCanSort()
                            ? {
                              className: `'cursor-pointer select-none sort' ${isStickyActive ? 'sticky-active' : 'sticky'}`,
                              onClick: header.column.getToggleSortingHandler(),
                            }
                            : {}
                          )} className="ms-1 ri-arrow-up-down-line text-muted fs-md align-middle cursor-pointer"></i>
                        }

                        {isFilter && (
                          <div className="position-relative">
                            {!isPts && !isMobile && !isacType && !isProfitLoss2 && !isCr && !isAction && !isBetStatus && !isUserStatus && !isBalance && !isCredit && !isIdColumn && !isIpColumn && !isExposure && !isProfitLoss && <div className="ms-1 cursor-pointer" onClick={() => toggleFilter(header.id)}><i className={`ri ri-filter-fill ${showFilter && activeFilterColumn === header.id && 'text-primary'}`}></i></div>}
                            {showFilter && activeFilterColumn === header.id && (
                              <div ref={filterRef}
                                className="filterDropDown bg-white position-absolute p-2 w-100 shadow-lg rounded z-1"
                                style={{ minWidth: "200px" }}
                              >
                                <>
                                  {
                                    isTypeColumn ? (
                                      <div className="ms-2">
                                        <Form.Select
                                          value={formValuesFilter[header.id] || ''}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            handleInputChange({ target: { value } }, header.id);
                                            table.getColumn(header.id)?.setFilterValue(value);
                                          }}
                                        >
                                          <option value="">All</option>
                                          <option value="1">Back</option>
                                          <option value="0">Lay</option>
                                        </Form.Select>
                                      </div>
                                    ) : isDomian ? <>
                                      <Select
                                        isClearable
                                        options={domainList || []}
                                        className="customSelect"
                                        value={
                                          domainList?.find(
                                            (domain: any) =>
                                              domain.value === domainId
                                          ) || null
                                        }
                                        onChange={handleDomain}
                                      />
                                    </> : islevelId ? <div className="ms-2">
                                      {/* <Form.Select name="level" value={formValuesFilter[header.id] || ''} onChange={(e) => {
                                        const value = e.target.value;
                                        handleInputChange({ target: { value } }, header.id);
                                        table.getColumn(header.id)?.setFilterValue(value);
                                        handleLevel(e);
                                      }}>
                                        <option value="">Select Level</option>
                                        {levelItem?.map((items) => (
                                          <option value={items?.level} key={items?.name}>{items?.name}</option>
                                        ))}
                                      </Form.Select> */}
                                      <Select
                                        isClearable
                                        options={levelItem || []}
                                        className="customSelect"
                                        value={
                                          levelItem?.find(
                                            (domain: any) =>
                                              domain.value ===  Number(levelId)
                                          ) || null
                                        }
                                        onChange={handleLevel}
                                      />
                                    </div>
                                      : (
                                        <Form.Control
                                          type="text"
                                          placeholder="Search..."
                                          name='user_name'
                                          value={formValuesFilter[header.id] || ''}
                                          onChange={(e) => handleInputChange(e, header.id)}
                                        />)
                                  }

                                </>

                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody>
            {getRowModel().rows.map(row => {
              const is_back = row?.original?.is_back === 1

              return (
                <tr key={row.id} className={showBck ? is_back ? "backBorder" : "layBorder" : ""}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id} className={`${tdClass} ${isStickyActive ? 'sticky-active' : 'sticky'}`}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {
          data?.length === 0 && <div className="noData text-center p-3 fs-md text-muted">No Data Found!</div>
        }

      </div>

      {isPagination && (
        <Row className={`${PaginationClassName} flex-wrap justify-content-center`}>
          <div className="col-sm mt-3">
            <div className="text-muted d-flex align-items-center">
              Showing
              <select
                className="mx-2 border"
                onChange={(e: any) => setLimit(e.target.value)}
              >
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              of{" "}
              <span className="fw-semibold ms-1">
                {totalData || data?.length}
              </span>{" "}
              <span className="ms-1">Results</span>
            </div>
          </div>
          <div className="col-sm-auto mt-3 mt-sm-0">
            <div className="pagination-wrap hstack gap-2 justify-content-center">
              <Button
                variant="link"
                className="page-item pagination-prev"
                disabled={currentPage === 1 || !currentPage}
                onClick={handlePreviousPage}
              >
                <i className="mdi mdi-chevron-left align-middle"></i>
              </Button>
              <ul className="pagination listjs-pagination mb-0">
                <li className={"active"}>
                  <Link to="#" className="page">
                    {currentPage || 1}
                  </Link>
                </li>
              </ul>
              <Button
                variant="link"
                className="page-item pagination-next"
                disabled={totalPages === currentPage}
                onClick={handleNextPage}
              >
                <i className="mdi mdi-chevron-right align-middle"></i>
              </Button>
            </div>
          </div>
        </Row>
      )
      }
    </Fragment>
  );
};

export default TableContainer;