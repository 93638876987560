import DepositModal from "Common/Modals/DepositModal";
import EditCreditReference from "Common/Modals/EditCreditReference";
import ExposureModal from "Common/Modals/ExposureModal";
import PermissionSetting from "Common/Modals/PermissionSetting";
import ResetPasswordModal from "Common/Modals/ResetPasswordModal";
import ShowPasswordModal from "Common/Modals/ShowPasswordModal";
import WithdrawModal from "Common/Modals/WithdrawModal";
import TableContainer from "Common/TableContainer";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState, } from "react";
import { Badge, Button, Dropdown, Modal, OverlayTrigger, Tooltip, Form, Popover } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CommissionSetting from "./CommissionSetting";
import PartnershipModal from "Common/Modals/PartnershipModal";
import UserMarketLimit from "Common/Modals/UserMarketLimit";
import EditStakeModal from "Common/Modals/EditStakeModal";
import ExposureLimitModal from "Common/Modals/ExposureLimitModal";
import PermissionModal from "Common/Modals/PermissionModal";
import ParentList from "Common/Modals/ParentList";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";
import TableEntry from "Common/TableEntry";
import { ListPagination } from "Common/ListPagination";

const userTypes: Record<number, string> = {
    0: "Super Admin",
    1: "Client",
    2: "Dealer",
    3: "Master",
    4: "Super",
    5: "Senior Super",
    6: "Hyper",
    7: "Sub Admin",
    8: "White Label",
    9: "Agent-9"

}

interface Props {
    userData: userListAgentRes[]
    getUserList: () => Promise<void>
    setUserId: Dispatch<SetStateAction<string>>;
    setLimit: Dispatch<SetStateAction<number>>
    totalResult: any
    limit: number,
    totalPages: any,
    setCurrentPage: Dispatch<SetStateAction<number>>
    currentPage: number,
    totalData: number,
    setFormValuesFilter: Dispatch<SetStateAction<{}>>
    formValuesFilter: {}
    domainList: DomainListPayload[];
    domainId: string;
    handleDomain: (selectedOption: any) => void
    setUserData: Dispatch<SetStateAction<userListAgentRes[]>>
    handleLevel: (e: any) => void
    levelId: string | number,
}

const UserListTableAgent: FC<Props> = ({
    userData, 
    getUserList, 
    setUserId, 
    setLimit, 
    totalResult, 
    limit, 
    totalPages, 
    setCurrentPage, 
    currentPage, 
    totalData,
    setFormValuesFilter,
    formValuesFilter,
    domainList,
    domainId,
    handleDomain,
    setUserData,
    handleLevel,
    levelId
}) => {
    const [showCreditReference, setCreditReference] = useState(false);
    const [showExposure, setExposure] = useState(false);
    const [showDeposit, setDeposit] = useState(false);
    const [showCommSet, setShowCommSet] = useState(false);
    const [showWithdraw, setWithdraw] = useState(false);
    const [showPasswordModal, setPasswordModal] = useState(false);
    const [showPermissionSetting, setPermissionSetting] = useState(false);
    const [showResetPasswordModal, setshowResetPasswordModal] = useState(false);
    const [userIds, setUserIds] = useState<string>("");
    const [showPartnership, setPartnership] = useState(false);
    const [showMarketLimit, setMarketLimit] = useState(false);
    const [showEditStake, setEditStake] = useState(false);
    const [showExposureLimit, setExposureLimit] = useState(false);
    const [showPermission, setPermission] = useState(false);
    const [creditRef, setCreditRef] = useState(0);
    const [userName, setUserName] = useState("");
    const [dataUser, setDataUser] = useState()
    const [settingData, setSettingData] = useState("");
    const [expData, setExpData] = useState(0);
    const [showParentList, setShowParentList] = useState(false);
    const [creaditData, setCreaditData] = useState(0);
    const [depositWithData, setDepositWithData] = useState(0);

    const toggleCreditReference = (cr: string, id: string, name: string) => {
        setCreditRef(Number(cr));
       
        setUserIds(id);
        setUserName(name)
        setCreditReference(!showCreditReference);
    };
    const togglePermissionSetting = (id: string) => {
        setUserIds(id);
        setPermissionSetting(!showPermissionSetting);
    };
    const toggleExposure = (id: string, name: string) => {
        setUserIds(id);
        setExposure(!showExposure);
        setUserName(name)
    };
    const toggleDeposit = (id: string, name: string) => {
       
        setUserName(name)
        setUserIds(id);
        setDeposit(!showDeposit);
    };
    const toggleCopmmitionSetting = (id: string, name: string) => {
        setUserIds(id);
        setUserName(name)
        setShowCommSet(!showCommSet);
    };
    const toggleWithdraw = (id: string, name: string) => {
        
        setUserName(name)
        setUserIds(id);
        setWithdraw(!showWithdraw);
    };
 
    const toggleResetPassword = (id: string, name: string) => {
        setUserIds(id);
        setUserName(name)
        setshowResetPasswordModal(!showResetPasswordModal);
    };
    const togglePartnershipSharing = (id: string, name: string) => {
        setUserIds(id);
        setUserName(name)
        setPartnership(!showPartnership);
    };
    const toggleMarketLimit = (id: string, settingId: string, name: string) => {
        setUserIds(id);
        setSettingData(settingId);
        setUserName(name)
        setMarketLimit(!showMarketLimit);
    };
    const toggleEditStake = (id: string, name: string) => {
        setUserIds(id);
        setUserName(name);
        setEditStake(!showEditStake);
    };
    const handleParentList = (id: string, name: string) => {
        setUserIds(id);
        setUserName(name)
        setShowParentList(!showParentList);
    };
    const toggleExposureLimit = (id: string, exp: number, name: string) => {
        setUserName(name)
        setUserIds(id);
        setExpData(exp);
        setExposureLimit(!showExposureLimit);
    };
    const togglePermission = (id: string, name: string, item: any) => {
        setUserIds(id);
        setUserName(name);
        setDataUser(item)
        setPermission(true);
    };

    const nav = useNavigate();
    const defaultData = useMemo(() => {
        if (!userData || !Array.isArray(userData)) {
            return [];
        }
        return userData.map((items, index: number) => ({
            no: (limit * (currentPage - 1)) + index + 1,
            searchQuery: items?.user_name || '',
            exp: items?.exposure_limit || 0,
            profitLoss: items?.client_pl || 0,
            balance: items?.available_pts || 0,
            point: items?.pts || 0,
            childLevel: items?.user_type_id || '',
            mobile: items?.mobile || '',
            creditReference: items?.credit_reference,
            levelId: items?.title,
            lockUser: items?.self_lock_user,
            parentLockUser: items?.parent_lock_user,
            lockBetting: items?.self_lock_betting,
            parentLockBetting: items?.parent_lock_betting,
            userId: items?._id,
            items: items,
            client_pl_share: items?.client_pl_share,

            DomainName: items?.domain_name,

            p_l: items?.client_pl,

        }));
    }, [userData]);

    const getBetLock = async (user_id: string, isLock:boolean) => {
        const { response } = await authServices.getBetlock({ user_id });
        if (response?.status) {
            snackbarUtil.success(response?.msg);
            updateBetLock(user_id,isLock?0:1);
        } else {
            snackbarUtil.error(response?.msg);
        }
    };

    const handleUserBet = (isLock: boolean, user_id: string) => {
       
        getBetLock(user_id, isLock);
    }

    const getUserLock = async (isLock: boolean, user_id: string) => {

        const { response } = await authServices.getUserLock({
            user_id,
            action: isLock ? 0 : 1
        });

        if (response?.status) {
            snackbarUtil.success(response?.msg);
            updateUserLock(user_id, isLock ? 0 : 1)
        } else {
            snackbarUtil.error(response?.msg);
        }
    };

    const handleUserLock = (isLock: boolean, user_id: string) => {
        getUserLock(isLock, user_id)
    }


    const updateUserData = (userId:string, newBalance:number) => {
        setUserData((prevData) =>
          prevData?.map((user) =>
            user._id === userId ? { ...user, available_pts: user?.available_pts + newBalance } : user
          )
        );
      };
    const updateUserCr = (userId:string, newBalance:number) => {
        setUserData((prevData) =>
          prevData?.map((user) =>
            user._id === userId ? { ...user, credit_reference: newBalance } : user
          )
        );
      };

      const updateUserLock = (userId:string, lock:number) => {
        setUserData((prevData) =>
          prevData?.map((user) =>
            user._id === userId ? { ...user, self_lock_user: lock } : user
          )
        );
      };
      const updateBetLock = (userId:string, lock:number) => {
        console.log(lock, "locklock")
        setUserData((prevData) =>
          prevData?.map((user) =>
            user._id === userId ? { ...user, self_lock_betting: lock } : user
          )
        );
      };


      const handleChildUserList=(idss:string)=>{
        setUserId(idss);
      }

   
    const columns = useMemo(
        () => [
            {
                header: "No.", accessorKey: "sNo", enableColumnFilter: false, enableSorting: true, cell: (cell: any) => {
                    return (<div>{((currentPage - 1) * limit) + cell.row.index + 1}</div>);
                },
            },
            {
                header: "User Name",
                accessorKey: "searchQuery",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    const isChildLevelOne = cell.row.original.childLevel === 1;
                    const isChildLevel = cell.row.original.childLevel;

                    return (
                        <div>
                            <span
                                className={!isChildLevelOne ? "cursor-pointer" : ""}
                                onClick={() => !isChildLevelOne && handleChildUserList(cell.row.original.userId)}
                            >
                                {cell.row.original.searchQuery}
                            </span>
                            <br />
                            <span>({userTypes[isChildLevel]})</span>
                        </div>

                    );
                },
            },
            { header: "Domain Name", accessorKey: "DomainName", enableColumnFilter: false, enableSorting: true},
            { header: "Mobile", accessorKey: "mobile", enableColumnFilter: false, enableSorting: true },
            {
                header: "C R",
                accessorKey: "creditReference",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    
                    return(
                    <span>
                        {cell.row.original.creditReference }{" "}
                        <Button variant="sm" onClick={() => toggleCreditReference(cell.row.original.creditReference, cell.row.original.userId, cell.row.original.searchQuery)}>
                            <i className="bx bx-edit-alt fs-lg align-middle"></i>
                        </Button>
                    </span>
                )},
            },
            {
                header: "Exposure",
                accessorKey: "exposure",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    <span
                        className={`badge cursor-pointer bg-body-secondary border border-light ${cell.row.original.exp > 0 ? "text-success" : "text-danger"}`} onClick={() => toggleExposure(cell.row.original.userId, cell.row.original.searchQuery)}>
                        {cell.row.original.exp}
                    </span>
                ),
            },
            {
                header: "Client P|L",
                accessorKey: "profitLoss",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => <span className={`badge cursor-pointer bg-body-secondary border border-light ${cell.row.original.profitLoss < 0 ? "text-danger" : "text-success"}`}> {cell.row.original.profitLoss?.toFixed(2)}</span>,
            },
            {
                header: "Client P|L %",
                accessorKey: "profitLoss2",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => <span className={cell.row.original.profitLoss < 0 ? "text-danger" : "text-success"}> {cell.row.original.client_pl_share?.toFixed(2)}%</span>
            },
            {
                header: "Pts",
                accessorKey: "Pts",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => <span className={cell.row.original.point < 0 ? "text-danger" : "text-success"}> {cell.row.original.point?.toFixed(2)}</span>
            },
            {
                header: "Avail. Balance",
                accessorKey: "balance",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => <span className={cell.row.original.balance < 0 ? "text-danger" : "text-success"}>{(cell.row.original.balance)?.toFixed(2)}</span>,
            },
            {
                header: "Credit",
                accessorKey: "credit",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    <div className="d-flex btn-group">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top"> Chips Deposit </Tooltip>}>
                            <Button variant="success bg-gradient" className="btn-sm cursor-pointer" style={{ minWidth: '29px' }} onClick={() => toggleDeposit(cell.row.original.userId, cell.row.original.searchQuery)}>
                                D
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top"> Chips Withdraw </Tooltip>}>
                            <Button variant="danger bg-gradient" className="btn-sm cursor-pointer" onClick={() => toggleWithdraw(cell.row.original.userId, cell.row.original.searchQuery)}>
                                W
                            </Button>
                        </OverlayTrigger>
                    </div>
                ),
            },
            {
                header: "User S",
                accessorKey: "userStatus",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    const userLock = cell.row?.original?.lockUser !== 0 || cell.row?.original?.parentLockUser !== 0
                    return (
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top"> User Status {userLock ? "Lock" : "Unlock"} </Tooltip>}>
                            <div className="text-center cursor-pointer" onClick={() => handleUserLock(userLock, cell.row.original.userId)}>
                                <i className={`fs-lg ${userLock ? "ri-lock-line" : "ri-lock-unlock-line"}`}></i>
                            </div>
                        </OverlayTrigger>
                    )
                },
            },
            {
                header: "Bet S",
                accessorKey: "betStatus",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    const lockBetting =  cell.row?.original?.lockBetting !== 0 ||  cell?.row?.original?.parentLockBetting !== 0
                    return(
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top_bet_lock"> Bet Status {lockBetting ? "Lock" : "Unlock"} </Tooltip>}>
                        <div className="text-center cursor-pointer" onClick={() => handleUserBet(lockBetting, cell.row.original.userId)}>
                            <i className={`fs-lg ${lockBetting ? "ri-lock-line" : "ri-lock-unlock-line"}`}></i>
                        </div>
                    </OverlayTrigger>
                )},
            },
            {
                header: "A/C Type",
                accessorKey: "levelId",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => <Badge className="badge-gradient-primary">{cell.row?.original?.levelId}</Badge>,
            },
            {
                header: "Action",
                accessorKey:"action",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cell: any) => {
                    const isChildLevelOne = cell.row.original.childLevel === 1;
                    return (
                        <div>
                            <Dropdown drop="start">
                                <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                                    <i className="fs-lg ri-more-2-fill"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item className="border-bottom py-2 bg-primary text-light" style={{cursor: 'auto'}}> 
                                        <i className="fs-lg me-2 ri-user-line align-middle"></i>
                                        <span className="fs-md">{cell.row.original.searchQuery}</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="border-bottom text-secondary py-2" onClick={() => toggleResetPassword(cell.row.original.userId, cell.row.original.searchQuery)}>
                                        <i className="fs-lg me-2 ri-key-2-fill align-middle"></i> Change Password
                                    </Dropdown.Item>
                                    <Dropdown.Item className="border-bottom text-success py-2" onClick={() => togglePartnershipSharing(cell.row.original.userId, cell.row.original.searchQuery)}>
                                        <i className="fs-lg me-2 ri-hand-coin-fill align-middle"></i> Partnership Sharing
                                    </Dropdown.Item>
                                    <Dropdown.Item className="border-bottom text-danger py-2" onClick={() => togglePermission(cell.row.original.userId, cell.row.original.searchQuery, cell.row?.original?.items)}>
                                        <i className="fs-lg me-2 ri-shield-user-fill align-middle"></i> Permission
                                    </Dropdown.Item>
                                    <Dropdown.Item className="border-bottom text-dark py-2" onClick={() => nav(`/import-market/${cell.row.original.userId}/${cell.row.original.searchQuery}`)}>
                                        <i className="fs-lg me-2 mdi mdi-human-child align-middle"></i> {isChildLevelOne ? "Block Market" : "Child Block Market"}
                                    </Dropdown.Item>
                                    <Dropdown.Item className="border-bottom text-success py-2" onClick={() => nav(`/downline-report/${cell.row.original.userId}/${cell.row.original.exp.toFixed(2)}/${cell.row.original.balance.toFixed(2)}/${cell.row.original.p_l.toFixed(2)}`)}>
                                        <i className="fs-lg me-2 ri-file-list-3-fill align-middle"></i> Report
                                    </Dropdown.Item>
                                    <Dropdown.Item className="border-bottom text-info py-2" onClick={() => toggleMarketLimit(cell.row.original.userId, cell.row.original.userSetting, cell.row.original.searchQuery)}>
                                        <i className="fs-lg me-2 ri-settings-4-fill align-middle"></i> Market Limit
                                    </Dropdown.Item>

                                    <Dropdown.Item className="border-bottom text-warning py-2" onClick={() => toggleCopmmitionSetting(cell.row.original.userId, cell.row.original.searchQuery)}>
                                        <i className="fs-lg me-2 ri-percent-fill align-middle"></i> Commission Setting
                                    </Dropdown.Item>
                                    <Dropdown.Item className="border-bottom text-success py-2" onClick={() => handleParentList(cell.row.original.userId, cell.row.original.searchQuery)}>
                                        <i className="fs-lg me-2 ri-parent-fill align-middle"></i> Parent List
                                    </Dropdown.Item>
                                    {
                                        !isChildLevelOne && <Dropdown.Item className="border-bottom text-primary py-2" onClick={() => nav(`/addChild-user/${cell.row.original.userId}/${cell.row.original.childLevel}`)}>
                                            <i className="fs-lg me-2 ri-user-add-fill align-middle"></i> Add Child
                                        </Dropdown.Item>
                                    }
                                    <Dropdown.Item className="border-bottom text-secondary py-2" onClick={() => toggleExposureLimit(cell.row.original.userId, cell.row.original.exp, cell.row.original.searchQuery)}>
                                        <i className="fs-lg me-2 ri-file-chart-fill align-middle"></i> Exposure Limit
                                    </Dropdown.Item>
                                    <Dropdown.Item className="text-info py-2" onClick={() => toggleEditStake(cell.row.original.userId, cell.row.original.searchQuery)}>
                                        <i className="fs-lg me-2 ri-edit-fill align-middle"></i> Edit Stake
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )
                },
            },
        ],
        [depositWithData, userData, creaditData, currentPage, limit]
    );

    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        const user = localStorage.getItem("adminDetails");

        if (user) {
            try {
                const parsedUser = JSON.parse(user);
                setUserDetails(parsedUser);
            } catch (error) {
                console.error("Failed to parse user details", error);
            }
        }
    }, []);

    



    return (
        <>
            <TableContainer
                isPagination={false}
                columns={columns}
                data={defaultData}
                sorting={true}
                customPageSize={limit}
                tableHeight={'560px'}
                divClassName="table-responsive"
                tableClass="table table-centered align-middle table-custom-effect table-nowrap userListTable mb-0 table-sticky-second table-sticky-head table-sticky-last"
                theadClass="table-light"
                PaginationClassName="align-items-center mt-1 pt-2"
                isBordered={false}
                setLimit={setLimit}
                totalPages={totalPages}
                limit={limit}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalData={totalData}
                isFilter={true}
                setFormValuesFilter={setFormValuesFilter}
                formValuesFilter={formValuesFilter}
                domainList={domainList}
                domainId={domainId}
                handleDomain={handleDomain}
                handleLevel={handleLevel}
                levelId={levelId}
            />

            <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
                <TableEntry
                    limit={limit}
                    handlelimitFilter={(value: number) => {setLimit(value); setCurrentPage(1)}}
                />
                <ListPagination
                    listTotal={{
                        total: totalData / limit ? totalData / limit : 1,
                        page: currentPage,
                    }}
                    handlePageFilter={(value: number) => setCurrentPage(value)}
                />
            </nav>

            {/* Modals */}
            <EditCreditReference updateUserCr={updateUserCr} setUserId={setUserId} setCreaditData={setCreaditData} getUserList={getUserList} userIds={userIds} userDetails={userDetails} userName={userName} creditRef={creditRef} setCreditRef={setCreditRef} show={showCreditReference} clickHandler={toggleCreditReference} />
            <PartnershipModal userName={userName} userIds={userIds} show={showPartnership} clickHandler={togglePartnershipSharing} />
            {/* <PermissionSetting userIds={userIds} show={showPermissionSetting} clickHandler={togglePermissionSetting} /> */}
            <ExposureModal userName={userName} userIds={userIds} show={showExposure} clickHandler={toggleExposure} />
            <CommissionSetting userName={userName} userDetails={userDetails} getUserList={getUserList} userIds={userIds} show={showCommSet} clickHandler={toggleCopmmitionSetting} />
            <DepositModal updateUserData={updateUserData}  depositWithData={depositWithData} setDepositWithData={setDepositWithData}  userName={userName} getUserList={getUserList} userIds={userIds} show={showDeposit} clickHandler={toggleDeposit} />
            <WithdrawModal updateUserData={updateUserData} depositWithData={depositWithData} setDepositWithData={setDepositWithData}  userName={userName} getUserList={getUserList} userIds={userIds} show={showWithdraw} clickHandler={toggleWithdraw} />
            {/* <ShowPasswordModal  userName={userName} userIds={userIds} show={showPasswordModal} clickHandler={toggleShowPassword} /> */}
            <ResetPasswordModal userName={userName} userDetails={userDetails} setshowResetPasswordModal={setshowResetPasswordModal} userIds={userIds} show={showResetPasswordModal} clickHandler={toggleResetPassword} />
            <UserMarketLimit userName={userName} settingData={settingData} userIds={userIds} show={showMarketLimit} clickHandler={toggleMarketLimit} />
            <EditStakeModal userName={userName} userIds={userIds} show={showEditStake} clickHandler={toggleEditStake} />

            <ExposureLimitModal userName={userName} userDetails={userDetails} getUserList={getUserList} setExposureLimit={setExposureLimit} exp={expData} userIds={userIds} show={showExposureLimit} clickHandler={toggleExposureLimit} />
            <ParentList userName={userName} userIds={userIds} show={showParentList} clickHandler={handleParentList} />
            <PermissionModal setPermission={setPermission} getUserList={getUserList} userIds={userIds} userName={userName} userData={dataUser} show={showPermission} clickHandler={togglePermission} />
        </>
    );
};

export default UserListTableAgent;