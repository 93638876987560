import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { settingServices } from 'Utils/setting/services';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const MarketAnalysis = () => {
    // const [marketData, setMarketData] = useState<DataEvent>();
    const [marketData, setMarketData] = useState<DataEvent | null>(null);
    const getEventAnylysis = async () => {
        const { response } = await settingServices.getEventAnylysis();
        setMarketData(response?.data || {});
    }
    const nav = useNavigate();
    useEffect(() => {

        getEventAnylysis();


        const interval = setInterval(() => {
            getEventAnylysis();
        }, 5000);


        return () => clearInterval(interval);
    }, []);

    const handleRoute = (matchid: string, sport_id: string, enableFancy: number, matchName: string, isActive: number) => {
        nav(`/match-detail/${matchid}/${sport_id}/${enableFancy}`, { state: matchName });
        localStorage.setItem("isAct", String(isActive));
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Market Analysis" pageTitle="" />

                    {
                        marketData && Object?.keys(marketData)?.map((keys) => {
                            console.log(marketData, "marketData")
                            return (
                                <>
                                    <h5 className='p-2'>{keys}</h5>
                                    {
                                      marketData &&  marketData[keys]?.map((items: any) => {
                                            return (
                                                <Card className='mt-3'>
                                                    <Card.Header className='bg-light py-2' >
                                                        <Row className='justify-content-between'>
                                                            <Col lg={8} xs={12}><strong className='fs-lg'>{items?.match_name}</strong></Col>
                                                            <Col lg={4} xs={12} className='text-start text-lg-end'>{moment(items?.match_date)?.format("DD-MM-YYYY hh:mm:ss A")}</Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row className='g-3'>
                                                            {
                                                                items && items?.markets && Object?.keys(items?.markets)
                                                                    ?.sort((a, b) => (a.split('|')[0] === "MATCH_ODDS" ? -1 : b.split('|')[0] === "MATCH_ODDS" ? 1 : 0)) /// Sort to show "matchodds" first
                                                                    ?.map((market) => {
                                                                        const name = market?.split('|')[0];
                                                                        return (
                                                                            <Col lg={4} key={market} onClick={() => handleRoute(items?.match_id, "4", 1, items?.match_name, 0)}>
                                                                                <h5>{name?.split('_').join(" ")}</h5>
                                                                                <div className='table-responsive border' style={{ maxHeight: '180px' }}>
                                                                                    <Table className='table-striped-columns mb-0'>
                                                                                        <tbody>
                                                                                            {
                                                                                                items?.markets[market]?.map((marketDataList: any) => {
                                                                                                    return (
                                                                                                        <tr key={marketDataList?.selection_name}>
                                                                                                            <td>{marketDataList?.selection_name}</td>
                                                                                                            <td className={`${marketDataList?.win_loss > 0 ? "text-success" : "text-danger"} text-center`}>
                                                                                                                {marketDataList?.win_loss}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    })
                                                            }

                                                            {
                                                                items && items?.fancies && Object?.keys(items?.fancies)?.map((fancies) => {
                                                                    return (
                                                                        <Col lg={4}>
                                                                            <h5>{fancies?.split('|')[0]}</h5>
                                                                            <div className='table-responsive border' style={{ maxHeight: '180px' }}>
                                                                                <Table className='table-striped-columns mb-0'>
                                                                                    <tbody>
                                                                                        {
                                                                                            items?.fancies[fancies]?.map((marketDataList: any) => {
                                                                                                return (
                                                                                                    <tr>
                                                                                                        <td>{marketDataList?.fancy_name}</td>
                                                                                                        <td className={`${marketDataList?.profit > 0 ? "text-success" : "text-danger"} text-center`}>{marketDataList?.profit}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </Table>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            )
                                        })
                                    }

                                </>
                            )
                        })
                    }




                </Container>
            </div>

        </React.Fragment>
    );
};

export default MarketAnalysis;
